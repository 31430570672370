export const New = () => {
  return (
    <div className="new">
      <p className="new__title">ما هي الأخبار؟</p>
      <p className="new__description">
        تم تحديث قسم المكافآت والعروض الترويجية.
      </p>
      <p className="new__description">
        تتم إضافة موقع أقرب فرع أو صراف آلي أو محطة طرفية إلى الخريطة.
      </p>
      <p className="new__description">تمت إضافة دعم معرف الوجه ومعرف اللمس.</p>
      <p className="new__description">
        تم إصلاح مشكلة في الإشعارات الفورية كان يعاني منها بعض مستخدمي Android
        10.
      </p>
      <p className="new__description">
        تم إصلاح مشكلة حيث كان النص في الإعدادات صغيرًا جدًا لمستخدمي Android.
      </p>
    </div>
  );
};
